const redirect = (redirectEndpoint) => {
  if (typeof window !== undefined) {
    const currentOrigin = window.location.origin;
    const expiredURL = new URL(currentOrigin + redirectEndpoint);
    window.location.href = expiredURL.href;
    localStorage.clear();
  }
};

const endpoint = (url) => url.split('/').pop().split('?')[0].toLowerCase();

const isValidEndpoint = (url, checkEndpoint) => endpoint(url) == checkEndpoint;

const checkExpired = (url, status) => {
  return status == 401 && !isValidEndpoint(url, 'logout');
};

export const checkRedirect = (url, status) => {
  if (checkExpired(url, status)) redirect('/misc/expired-token');
};
