import { checkRedirect } from '@UTILs/redirect';

const STATIC_PAYLOAD = {
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  redirect: 'follow',
  referrerPolicy: 'no-referrer',
  method: 'POST'
};

const AUTH = {
  get Authorization() {
    return `Bearer ${localStorage.getItem('accessToken')}`;
  }
};

const _HEADERS = (headers) => {
  const _headers = {
    'Content-Type': 'application/json',
    ...headers
  };
  return () => _headers;
};

/**
 *
 * @param {String} url API URL
 * @param {Object} data form data / data in json format to be sent in post request
 * @param {Object} params (optional) aditional params like additional headers or isForm/isWithOutAuth flage boolean value
 * @returns {Promise} response.json() / response.text() from fetch or in case of failure throws exception which could be handled in .catch()
 */
export async function postData(url = '', data = {}, params = {}) {
  const { isWithOutAuth, isForm, headers } = params;
  let HEADERS = _HEADERS(headers)();

  const payload = {
    ...STATIC_PAYLOAD,
    body: isForm ? data : JSON.stringify(data)
  };

  if (!isWithOutAuth) {
    HEADERS = _HEADERS(AUTH)();
  }
  if (!isForm) {
    payload['headers'] = HEADERS;
  }
  const response = await fetch(url, payload);

  if (response.ok || response.status === 302) {
    return response.status === 204 ? response.text() : response.json();
  }

  const errorData = await response.json();
  const errorCode = response.status;

  if ([400, 403, 500].includes(errorCode)) {
    throw new Error(errorData.message);
  }

  checkRedirect(url, response.status);

  throw new Error(errorData.body);
}

/**
 *
 * @param {String} url API URL
 * @param {Object} data json data to be sent in request query params
 * @returns {Promise} response.json() from fetch or in case of failure throws exception which could be handled in .catch()
 */
export async function getData(url = '', data = {}) {
  const response = await fetch(url, { headers: _HEADERS(AUTH)() });

  checkRedirect(url, response.status);

  if (
    response.ok ||
    response.status === 302 ||
    response.status === 400 ||
    response.status === 500
  ) {
    if (response.status !== 204) {
      const resp = response.json();
      return resp;
    } else {
      return Promise.resolve({});
    }
  }

  const errorData = await response.json();
  const errorCode = response.status;

  if ([400, 403, 500].includes(errorCode)) {
    throw new Error(errorData.message);
  }

  throw new Error(errorData.body);
}
