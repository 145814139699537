// ** React Imports
import { Suspense, lazy, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store';

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability';
import { ToastContainer } from 'react-toastify';
import { AbilityContext } from './utility/context/Can';
import { ThemeContext } from './utility/context/ThemeColors';
import { IntlProviderWrapper } from './utility/context/Internationalization';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** Fake Database
import './@fake-db';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';
// import { GlobalProvider } from './GlobalContext';
import { GlobalProvider } from './context/GlobalContext';
import { BrandDetailsProvider } from './context/adsData';
import { AccessControlProvider } from './context/AccessControl';

import TagManager from 'react-gtm-module';

import SocketContext from './context/SocketContext';
import io from 'socket.io-client';
import { UserContextProvider, useUserContext } from './context/UserContext';

const LazyApp = lazy(() => import('./App'));
const tagManagerArgs = {
  gtmId: 'GTM-T5BL32BD'
};

TagManager.initialize(tagManagerArgs);

function App() {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize the socket connection

    const newSocket = io(`${process.env.REACT_APP_SOCKET_ENDPOINT}`, {
      path: '/api/socket.io',
      transports: ['websocket', 'polling'],
      withCredentials: true,
      timeout: 20000,
      reconnection: true, // Enable reconnection
      reconnectionAttempts: 10, // Retry indefinitely
      reconnectionDelay: 1000, // Initial delay before reconnection
      reconnectionDelayMax: 5000
    });
    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error.message);
    });

    newSocket.on('connect_timeout', () => {
      console.error('Socket connection timeout');
    });

    newSocket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
    });

    // Set the socket state
    setSocket(newSocket);

    // Clean up the socket connection on component unmount
    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  return (
    <Provider store={store}>
      <AccessControlProvider>
        <GlobalProvider>
          <BrandDetailsProvider>
            <UserContextProvider>
              <Suspense fallback={<Spinner />}>
                <AbilityContext.Provider value={ability}>
                  <SocketContext.Provider value={socket}>
                    {' '}
                    {/* Provide socket context */}
                    <ThemeContext>
                      <IntlProviderWrapper>
                        <LazyApp />
                        <ToastContainer newestOnTop />
                      </IntlProviderWrapper>
                    </ThemeContext>
                  </SocketContext.Provider>
                </AbilityContext.Provider>
              </Suspense>
            </UserContextProvider>
          </BrandDetailsProvider>
        </GlobalProvider>
      </AccessControlProvider>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
